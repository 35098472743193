<template>
  <div class="mx-6">
    <div class="vx-row margin-btm" style="width: 100%">
      <div class="vx-col sm:w-1/3 w-full">
        <span>Partner Code</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-input class="w-full" v-model="otherPayment.PersonCode" />
      </div>
    </div>
    <br />
    <div class="vx-row margin-btm" style="width: 100%">
      <div class="vx-col sm:w-1/3 w-full">
        <span>Partner Name</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-input class="w-full" v-model="otherPayment.PersonName" />
      </div>
    </div>
    <br />
    <div class="vx-row margin-btm" style="width: 100%">
      <div class="vx-col sm:w-1/3 w-full">
        <span>Posting Date</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <datepicker
          class="w-full"
          v-validate="'required'"
          :inline="false"
          name="Posting Date"
          v-model="date_posting"
          placeholder="Date Posting"
          :disabled-dates="{from: new Date()}"
        ></datepicker>
      </div>
    </div>
    
    <br />
    <div class="vx-row margin-btm" style="width: 100%">
      <!-- IF TOTAL SELECTED < AMOUNT v-if="totalSelected <= otherPayment.Amount" -->
       <vs-alert
        v-if="remaining == 0"
        color="success"
        class="mt-3"
        icon-pack="feather"
        close-icon="icon-x"
      >
        Total Amount : {{ priceFormat(otherPayment.DepositValue) }}
        <hr />
        Total Amount Selected : {{ priceFormat(totalSelected) }}
        <hr />
         Additional Value : {{ priceFormat(totalAdditionalValue) }}
        <hr />
        Remaining : {{ formatPrice(remaining) }}
        <!-- Remaining :  <template v-if="remaining<0">-</template>{{ priceFormat(remaining) }} -->
      </vs-alert>
      <!-- IF TOTAL SELECTED > AMOUNT -->
      <vs-alert
            v-else
            color="warning"
            class="mt-3"
            icon-pack="feather"
            close-icon="icon-x"
          >
            Total Amount : {{ priceFormat(otherPayment.DepositValue) }}
            <hr />
            Total Amount Selected : {{ priceFormat(totalSelected) }}
            <hr />
            Additional Value : {{ priceFormat(totalAdditionalValue) }}
            <hr />
            Remaining :  {{  formatPrice(remaining) }}
          </vs-alert> 
    </div>

    <vs-divider style="width: 100%; margin-left: 2%"><b>Selected DN by Excel</b></vs-divider>
      <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
        <div class="vx-col sm:w-1/3 w-full">
          <span>File</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <div class="vx-col sm:w-4/5 w-full">
            <input
              id="fileSelectedDN"
              name="fileSelected"
              class="w-full inputx"
              type="file"
              ref="fileSelected"
              multiple="multiple"
              accept=".xlsx"
            />
          </div>
        </div>
      </div>
      <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
        <div class="vx-col sm:w-1/3 w-full">
          <span></span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <div class="vx-col sm:w-4/5 w-full">
            <vs-button class="mr-3 mb-2" @click="loadExcel"
              >Selected</vs-button
            >
          </div>
        </div>
      </div>
    <vs-divider style="width: 100%; margin-left: 2%"><b>Debit Note</b></vs-divider>

    <vs-button 
      class="mr-3 mb-2" 
      :hidden="trueSelected"
      @click="selectedDN"
    >
      Selected DN
    </vs-button>

    <vs-button 
      class="mr-3 mb-2" 
      :hidden="falseSelected"
      @click="allDN"
    >
      All DN
    </vs-button>
    
    <div style="width: 100%">
      <div>
        <!-- <vs-button
				v-on:click="doPaymentApproval()"
				color="primary"
				icon-pack="feather"
				icon="icon-inbox"
				>Approval</vs-button
			> -->
        <vs-table
          search
          stripe
          border
          description
          :sst="true"
          :data="table.data"
          :max-items="table.length"
          :total="table.total"
          @search="handleSearch"
          @change-page="handleChangePage"
          @sort="handleSort"
        >
          <template slot="header">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div
                class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
              >
                <span class="mr-2"
                  >{{ this.table.start }} - {{ this.table.end }} of
                  {{ this.table.total }}</span
                >
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item
                  v-for="item in table.limits"
                  :key="item"
                  @click="handleChangelength(item)"
                >
                  <span>{{ item }}</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </template>
          <template slot="thead">
            <vs-th width="5%"></vs-th>
            <vs-th>Supplier</vs-th>
            <vs-th sort-key="code">DN Info</vs-th>
            <vs-th sort-key="code">Paid Now</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr
              :data="tr"
              :key="indextr"
              v-for="(tr, indextr) in data"
              :class="data[indextr].class"
            >
              <vs-td>
                <vs-checkbox
                  v-on:click="check(tr.dn_id, indextr)"
                  :checked="checked.includes(tr.dn_id)"
                >
                </vs-checkbox>
              </vs-td>
              <vs-td :data="data[indextr].supplier_name">
                <div class="vx-row ml-0">
                  <span class="w-5/5"
                    >{{ data[indextr].supplier_code }} -
                    {{ data[indextr].supplier_name }}</span
                  >
                </div>
                <div class="vx-row ml-0">
                  <span class="w-5/5"
                    >{{ data[indextr].supplier_address }},
                    {{ data[indextr].supplier_city }} ({{
                      data[indextr].supplier_contact_name
                    }}
                    - {{ data[indextr].supplier_phone }})</span
                  >
                </div>
              </vs-td>
              <vs-td :data="data[indextr].code">
                <div class="vx-row ml-0">
                  DN Code : {{ tr.dn_code }}<br />
                  DN Type : {{ tr.type }}<br />
                  DN Value : {{ priceFormat(tr.total_dn) }}<br />
                  DN Unpaid : {{ tr.unpaid_value }}
                </div>
              </vs-td>
              <vs-td style="text-align: right">
                <template v-if="checked[checked.length - 1] == tr.dn_id">
                  <vs-input
                    class="w-full"
                    @keypress="isNumber($event)"
                    v-model="dataChecked[checked.length - 1].paid_now"
                    @keyup="handlePrice(checked.length - 1)"
                  />
                </template>
                <template v-else-if="checked.includes(tr.dn_id)">
                  {{ tr.paid_now }}
                </template>
                <template v-else> 0 </template>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <vs-pagination
          style="padding-top: 5px"
          :total="table.totalPage"
          v-model="setPage"
        />
      </div>
    </div>
    <br />

    <!-- <div class="vx-row mb-2" v-for="(input,k) in inputs" :key="k">

      <div class="vx-col w-1/4">
        <label class="vs-input--label">COA</label>
        <multiselect
          class="selectExample"
          v-model="input.coa_id"
          :options="optionCoa"
          :multiple="false"
          :allow-empty="true"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          track-by="Code"
          label="Code"
          :disabled="disabled == true"
          
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">{{ dt.option.Code }} - {{ dt.option.Name }}</span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">{{ dt.option.Code }} - {{ dt.option.Name }}</span>
            </div>
          </template>
        </multiselect>
      </div>

      <div class="vx-col w-1/4">
        <label class="vs-input--label">Cost Center</label>
        <multiselect
          class="selectExample"
          v-model="input.cost_center_id"
          :options="optionCostCenter"
          :multiple="false"
          :allow-empty="true"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          track-by="Name"
          label="Name"
          :disabled="disabled == true"
         
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">{{ dt.option.Name }}</span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">{{ dt.option.Name }}</span>
            </div>
          </template>
        </multiselect>
      </div>

      <div class="vx-col w-1/4">
       <label class="vs-input--label">Value</label>
       <vs-input
                    class="w-full"
                    type ="number"
                    
                    v-model="input.additional_value"
                    @change="totalAdditionalValue"
                  />
                
       
      </div>

      <div class="vx-col w-1/4" style="margin-top: 10px;">
        <div class="whitespace-no-wrap">
          <div class="mt-4 flex mr-1">
            <vs-button style="margin-right: 5px;" size="small" @click="removeField(k)" icon-pack="feather" icon="icon-minus" v-show="k || ( !k && inputs.length > 1)">
            </vs-button>
            <vs-button style="margin-right: 5px;" size="small" @click="addField(k)" icon-pack="feather" icon="icon-plus" v-show="k == inputs.length-1">
            </vs-button>
          </div>
        </div>
      </div>
    </div> -->
      
    <div class="vx-row margin-btm" style="width: 100%; margin-left: 0%">
      <div class="vx-col sm:w-1/3 w-full">
        <span></span>
      </div>
  <br />      
      <vs-button @click="handleSubmit(false)" icon-pack="feather" icon="icon-save">
        Submit
      </vs-button>
    </div>

  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import {
  dataInvoiceCustomer,
  postPaymentLine,
} from "../../../../../services/api/payment";
import moment from "moment";
import XLSX from "xlsx";
export default {
  props: {
    otherPayment: Object,
  },
  data() {
    return {
      dataPayment: [],
      checkedAll: false,
      checked: [],
      dataChecked: [],
      inputs: [{
        additional_value: 0,
        cost_center_id: null,
        coa_id: null,
      }],
      additionalCoa: [{
        additional_value: 0,
        cost_center_id: null,
        coa_id: null,
      }],
      totalAdditionalVal: 0,
      additionalValue: [],
      costCenter: [],
      coa: [],
      optionCostCenter: [],
      optionCoa: [],
      debitNoteData: [],
      table: {
        data: [],
        length: 5,
        page: 1,
        search: "",
        order: "id",
        sort: "asc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [5, 10, 25, 50, 100],
        start: 1,
        end: 0,
      },
      isSelected: false,
      activePromptConfrm: false,
      supplierDepositID: 0,
      date_posting: "",
      DnData: [],
    };
  },
  components: {
    Datepicker
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
    totalSelected: function () {
      let total = this.dataChecked.reduce(
        (acc, value) =>
          acc +
          parseFloat(value.paid_now.toString().replace(/,/g, "").toString()),
        0
      );
      // if (isNaN(total)) {
      //   this.dataChecked.map((v) => {
      //     console.log(v.paid_now);
      //     if (isNaN(v.paid_now) || v.paid_now == "") {
      //       v.paid_now = 0;
      //     }
      //   });
      //   return 0;
      // }
      return total;
    },
    totalAdditionalValue : function () {
      console.log("valueChanged")
      let totalValue = 0;
      if (this.inputs.length > 1) {
        for (let i = 0; i < this.inputs.length; i++) {
          let totalVal = 0;
          totalVal = this.inputs[i].additional_value == undefined ? 0 : parseFloat(this.inputs[i].additional_value);
          totalValue = totalValue + totalVal;
        }
      } else {
        totalValue = this.inputs[0].additional_value == undefined ? 0 : parseFloat(this.inputs[0].additional_value);
      }
      console.log("totalValue", totalValue)
      return totalValue;
    },

    remaining: function () {
      let remaining = 0;
      remaining = this.otherPayment.DepositValue - this.totalSelected + this.totalAdditionalValue;
      remaining = parseFloat(remaining).toFixed(2);
      console.log("remaining", remaining)
      return remaining;
      
    },
    
    falseSelected: function () {
      let selectDN = this.isSelected === false ? true : false
      return selectDN;
    },

    trueSelected: function () {
      let selectDN = this.isSelected === true ? true : false
      return selectDN;
    },
  },
  mounted() {
    console.log(this.otherPayment, "payment on mounted");
    // this.getData();
    // this.getOptionCostCenter();
    // this.getOptionCoa();
  },
  methods: {
    async loadExcel() {
      this.$vs.loading();
      var file = this.$refs.fileSelected.files[0]
      const data = await file.arrayBuffer();
      var workbook = XLSX.readFile( data, {dense: true});
      var worksheet = workbook.Sheets[workbook.SheetNames[0]];
      var json = XLSX.utils.sheet_to_json(worksheet, {
        header: 2
      });
      document.querySelector("#fileSelectedDN").value = "";
      const noDNInternalArray = json.map(item => item["No DN Internal"]);

      this.$http
        .get("/api/v1/debit-note/dn/search-with-code", {
          params: {
            debit_note_code: noDNInternalArray,
            territory_id: this.otherPayment.TerritoryID,
            territory_area_id: this.otherPayment.TerritoryAreaID,
            status: 1,
            supplier_id: this.otherPayment.PersonID,
            settlement_method: "Payment",
            type: "payment-for-dn",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            resp.data.debitNote.map((row) => {
              this.DnData.push({
                ...row,
                ...{
                  paid_now: row.TotalDnValue - row.UsedDnValue,
                  unpaid_value: row.TotalDnValue - row.UsedDnValue,
                },
              });
            });
            this.processSelectedDN(json)
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get DN option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });

      // this.$vs.loading.close();
      // }
        
      // reader.readAsArrayBuffer(file);
      // console.log(file)
      
    },

    processSelectedDN(json) {
      
      this.$loa
      if (this.DnData.length <= 0) {
        this.$vs.notify({
            color: "warning",
            title: "Information",
            text: "DN not found",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
        });
        return
      }

      json.map((v,r) => {
        // if (r > 0 && v.length > 0) {
          const filter = this.DnData.filter(subv => {
            return v["No DN Internal"] == subv.dn_code
          })
          if (filter.length > 0) {
            let index = this.table.data.findIndex( x => x.dn_code === filter[0].dn_code ); // get data index
            const unUsed = parseFloat(filter[0].total_dn) - parseFloat(filter[0].used_dn_value)
            // console.log(unUsed, v[2])
            if (unUsed >= parseFloat(v["Amount"])) {
              const checkSelected = this.dataChecked.filter(subv => {
                return v["No DN Internal"] == subv.dn_code
              }) 
              // if (index > 0) {
                if (checkSelected.length < 1) {
                  // this.check(filter[0].id, index)

                  let dataTable = this.table.data.filter(subv => {
                    return filter[0].dn_code == subv.dn_code
                  }) 

                  if (dataTable.length > 0) {
                    // console.log(dataTable[0], "datadnsfaon");
                    dataTable[0].paid_now = this.priceFormat(v["Amount"])
                  }
                  filter[0].paid_now = this.priceFormat(v["Amount"])
                  this.checked.push(filter[0].id);
                  this.dataChecked.push(filter[0]);
                } else {
                  this.$vs.notify({
                    color: "warning",
                    title: "Information",
                    text: v["No DN Internal"] + " already selected",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                  });
                }
              // }
            } else {
              this.$vs.notify({
                  color: "warning",
                  title: "Information",
                  text: v["No DN Internal"] + " value greater than remaining debit note",
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-x-circle",
              });
            }
          } else {
            this.$vs.notify({
                color: "warning",
                title: "Information",
                text: v["No DN Internal"] + " not found",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
            });
          }
          // console.log(filter)
          // console.log(v,r, 'r')
        // }
      })
    },
    selectedDN() {
      // console.log(this.dataChecked);
      this.table.data = [];
      this.isSelected = true;

      this.dataChecked.map((row) => {
        console.log(row);
        this.table.data.push({
          ...row,
          ...{
          //   paid_now: this.priceFormat(row.total_dn - row.used_dn_value),
            unpaid_value: this.priceFormat(
              row.total_dn - row.used_dn_value
            ),
          },
        });
      });
    },
    allDN() {
      // console.log(this.dataChecked);
      this.table.data = [];
      this.isSelected = false;

      this.debitNoteData.map((row) => {
        let filterData = this.dataChecked.filter((v) => {
          return v.dn_id == row.dn_id;
        });

        let paidNow = "0";

        if (filterData.length > 0) {
          paidNow = filterData[0].paid_now;
        } else {
          paidNow = this.priceFormat(row.total_dn - row.used_dn_value);
        }

        this.table.data.push({
          ...row,
          ...{
            paid_now: paidNow,
            unpaid_value: this.priceFormat(row.total_dn - row.used_dn_value),
          },
        });
      });
    },
    addField() {
      this.inputs.push({
        additional_value: 0,
        cost_center_id: null,
        coa_id: null,
      })
      this.additionalCoa.push({
        additional_value: 0,
        cost_center_id: null,
        coa_id: null,
      })
      console.log(this.inputs)
    },
    removeField(index) {
      this.inputs.splice(index, 1)
    },
    openConfirm(){
      this.activePromptConfrm = true
    },
    closeConfirm() {
      this.activePromptConfrm = false
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    handlePrice(index) {
      let paid = this.dataChecked[index].paid_now.toString().replace(/,/g, "");
      if (paid == "") {
        this.dataChecked[index].paid_now = 0;
      }
      let unpaid = this.dataChecked[index].unpaid_value
        .toString()
        .replace(/,/g, "");
      if (unpaid == "") {
        this.dataChecked[index].paid_now = 0;
      }
      if (isNaN(paid) || paid === "" || paid === null) {
        paid = 0; // Update the local paid variable
      }
      console.log(this.dataChecked[index]);
      if (parseFloat(unpaid) < parseFloat(paid)) {
        paid = this.dataChecked[index].unpaid_value;
      }
      this.dataChecked[index].paid_now = this.formatPrice(paid);
    },
    dateFormat(value) {
      return moment(String(value)).format("DD/MM/YYYY");
    },
    check(val, index) {
      if (this.checked.includes(val)) {
        this.checked.splice(this.checked.indexOf(val), 1);
        this.dataChecked = this.dataChecked.filter((v) => {
          return v.dn_id != val;
        });
      } else {
        this.checked.push(val);

        // this.table.data[index].paid_now = this.table.data[index].dn_value - this.table.data[index].used_dn_value
        this.dataChecked.push(this.table.data[index]);
      }
    },
    // paramData() {
    //   return {
    //     debit_note_id: this.debitNote.selected.dn_id,
    //     cost_center_id: this.costCenter.ID,
    //     chart_of_account_id: this.coa.ID,
    //     adjustment_value: parseFloat(this.adjustment_value.replace(/,/g, '')),
    //     control_value: parseFloat(this.control_value.replace(/,/g, '')),
    //     remaining_value: parseFloat(this.remaining_value.replace(/,/g, '')),
    //     posting_date: moment(this.posting_date).format('YYYY-MM-DD'),
    //     reason: this.reason,
    //     note: this.note,
    //     file: this.paramsUpload,
    //   };
    // },
    getOptionCoa() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/chart-of-account", {
          params: {
            order: "code",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            var _this = this
            resp.data.records.forEach(function(e){
              _this.optionCoa.push(e.coa);
            });
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Coa option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    getOptionCostCenter() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/cost-center", {
          params: {
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.optionCostCenter = resp.data.records;
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Cost Center option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;
      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;
      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }
      if (this.table.totalSearch < this.table.total) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    getData() {
      this.$vs.loading();
      // this.setCoaSelected(this.ChartOfAccountID);
      // this.setCostCenterSelected(this.CostCenterID);
      this.$http
        .get("/api/v1/debit-note/dn", {
          params: {
            length: this.table.length,
            page: this.table.page,
            search: this.table.search,
            order: this.table.order,
            sort: this.table.sort,
            territory_id: this.otherPayment.TerritoryID,
            territory_area_id: this.otherPayment.TerritoryAreaID,
            status: 1,
            supplier_id: this.otherPayment.PersonID,
            settlement_method: "Payment",
            type: "payment-for-dn",
    
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.table.total = resp.data.total_records;
            this.table.totalPage = resp.data.total_page;
            this.table.totalSearch = resp.data.total_record_seardh;
            this.table.length = resp.data.total_record_per_page;
            this.table.data = [];
            this.debitNoteData = resp.data.records;
            this.data = resp.data.records;
            console.log("isi data: " );
            console.log(resp.data);
            resp.data.records.map((row) => {
              let filterData = this.dataChecked.filter((v) => {
                return v.dn_id == row.dn_id;
              });

              let paidNow = "0";

              if (filterData.length > 0) {
                paidNow = this.priceFormat(filterData[0].paid_now);
              } else {
                paidNow = this.priceFormat(row.total_dn - row.used_dn_value);
              }

              this.table.data.push({
                ...row,
                ...{
                  paid_now: paidNow,
                  unpaid_value: this.priceFormat(row.total_dn - row.used_dn_value),
                },
              });
            });

            this.setStartEnd();
            this.$vs.loading.close();
            console.log(this.table.data.dn_id);
          }
        });
    },
    addChecked(val, index) {
      console.log(this.dataChecked, "check");
      console.log(this.checked.length, "leng");
      if (this.checked.includes(val)) {
        this.checked.splice(this.checked.indexOf(val), 1);
        this.dataChecked = this.dataChecked.filter((v) => {
          return v.ID != val;
        });
      } else {
        this.checked.push(val);
        this.dataChecked.push(this.dataPayment[index]);
      }
    },
    handleSubmit(isCashBank) {
      
      console.log("handleSubmit jalan");
      // let lines = []
      // this.dataChecked.map((row, index) => {
      //   lines.push({
      //     paid: parseFloat(row.paid_now.toString().replace(/[^,\d]/g, "").toString()),
      //     debit_note_id: row.dn_id
      //   })
      // });
      // const params = {
      //   other_payment_id: this.otherPayment.ID,
      //   line: lines,
      // };
      
      let additionalCoa = null;
      // if (this.additionalValue > 0) {
      //   for (let i = 0; i < this.inputs.length; i++) {
      //     this.additionalCoa[i].additional_value = parseFloat(this.inputs[i].additional_value);
      //     this.additionalCoa[i].coa_id = this.inputs[i].coa_id.ID;
      //     this.additionalCoa[i].cost_center_id = this.inputs[i].cost_center_id.ID;
      //   }

      //   additionalCoa = this.additionalCoa;
      // }
      
      let lines = [];
      console.log(this.inputs);
        // if (this.totalAdditionalValue != 0) {
        //   for (let i = 0; i < this.inputs.length; i++) {
            // if (this.inputs[i].coa_id == 0 || this.inputs[i].coa_id == undefined || this.inputs[i].coa_id == null || this.inputs[i].coa_id == "") {
            //   this.$vs.notify({
            //     title: "Failed",
            //     text: "COA required",
            //     iconPack: "feather",
            //     position: "top-right",
            //     icon: "icon-alert-circle",
            //     color: "warning",
            //   });
            // return false;
            // } else {
              
            // }
            
            
            // if (this.inputs[i].cost_center_id == 0 || this.inputs[i].cost_center_id == undefined || this.inputs[i].cost_center_id == null || this.inputs[i].cost_center_id == "") {
            //   this.$vs.notify({
            //     title: "Failed",
            //     text: "Cost Center required",
            //     iconPack: "feather",
            //     position: "top-right",
            //     icon: "icon-alert-circle",
            //     color: "warning",
            //   });
            // return false;
            // }
          // }
          
          
          // return true;
        // }
        // let absRemaining = Math.abs(this.remaining);
        console.log("remaining-----------", this.remaining);
        if (this.date_posting == "") {
          this.$vs.notify({
            title: "Error",
            text: "please enter posting date",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-check",
          });
          return
        }

        if (this.remaining < 0) {
          this.$vs.notify({
            title: "Failed",
            text: "Remaining must more than 0!",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-alert-circle",
            color: "warning",
          });
          return false;
        }

        if (this.dataChecked.length < 1) {
          this.$vs.notify({
            title: "Failed",
            text: "Please choose one invoice",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-alert-circle",
            color: "warning",
          });
          return false;
        }

        const checkPaidZero = this.dataChecked.filter((row) => {
          return row.paid_now == 0;
        });
        if (checkPaidZero.length > 0) {
          this.$vs.notify({
            title: "Failed",
            text: "Selected paid now must greater than 0!",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-alert-circle",
            color: "warning",
          });
          return false;
        }
        
        this.$vs.loading();
        this.dataChecked.map((row, index) => {
          lines.push({
            paid: parseFloat(
              row.paid_now
              .toString()
              .replace(/,/g, "")
              .toString()
            ),
            debit_note_id: row.dn_id,
          });
        });

      const params = {
        other_payment_id: this.otherPayment.ID,
        date_posting: moment(this.date_posting == '' ?new Date(): this.date_posting ).format('YYYY-MM-DD'),
        line: lines,
        person_id: this.otherPayment.PersonID,
        // coa_id: this.coa,
        // cost_center_id: this.costCenter,
        // additional_value: this.additionalValue,
        supplier_deposit_id: this.supplierDepositID,
        additional_coa: additionalCoa,
        payment_value: this.totalSelected + this.totalAdditionalValue
      };
      // console.log(params, "params");
      console.log("params 656", params);
      this.$http.post("/api/v1/supplier-deposit/for-dn/lines", params).then((result) => {
        console.log("seharusnya sudah", result.code);
        this.$vs.loading.close();
        if (result.code == 500) {
          this.$vs.notify({
            title: "Failed",
            text: result.message,
            iconPack: "feather",
            position: "top-right",
            icon: "icon-alert-circle",
            color: "warning",
          });
        } else if (result.code == 200) {
          console.log(result);
          this.$vs.notify({
            title: "Success",
            text: result.message,
            iconPack: "feather",
            position: "top-right",
            icon: "icon-alert-circle",
            color: "success",
          });
        }
        this.$nextTick(() => {
          // this.$emit("closeDetail");
          this.$emit("handleClose", true);
          this.$vs.loading.close();
        });
      });
    },
    // setCoaSelected(id) {
    //     this.$vs.loading();
    //     this.$http.get("/api/v1/master/chart-of-account/" + id).then((resp) => {
    //         if (resp.status == "success") {
    //         // this.coa = resp.data[0].coa;
    //         this.$vs.loading.close();
    //         } else {
    //         this.$vs.loading.close(); 
    //         }
    //     });
    // },
    // setCostCenterSelected(id) {
    //   this.$vs.loading();
    //   this.$http.get("/api/v1/master/cost-center/" + id).then((resp) => {
    //     if (resp.status == "success") {
    //       this.costCenter = resp.data;
    //       this.$vs.loading.close();
    //     } else {
    //       this.$vs.loading.close();
    //     }
    //   });
    // },
    formatPrice(angka, prefix = "") {
      return angka
        .toString()
        .replace(/,/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    addAllChecked() {
      if (this.checkedAll == false) {
        for (var i = 0; i < this.dataotherPayment.length; i++) {
          if (this.checked.includes(this.dataPayment[i].ID)) {
            //
          } else {
            this.checked.push(this.dataPayment[i].ID);
            this.dataChecked.push(this.dataPayment[i]);
          }
        }
      } else {
        for (var j = 0; j < this.dataotherPayment.length; j++) {
          this.checked.splice(this.checked.indexOf(this.dataPayment[j].ID), 1);
          this.dataChecked = this.dataChecked.filter((v) => {
            return v.ID != this.dataPayment[j].ID;
          });
        }
      }
    },
    loadInvoice() {
      const invoiceCustomer = dataInvoiceCustomer(
        this.otherPayment.PartnerCode
      );
      invoiceCustomer.then((r) => {
        this.dataPayment = [];
        r.data.invoice.map((row) => {
          this.dataotherPayment.push({
            ...row,
            ...{ paid_now: row.Unpaid, Note: "" },
          });
        });
        console.log(this.dataPayment);
      });
    },
  },
};
</script>